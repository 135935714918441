import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import RingpoemsIntro from './ringpoemsIntro'

// markup
const Posies_t = () => {
  return (
   <Layout >
      <Head title='Ring Poems (Posies) in English - T-V'/>
     
     <RingpoemsIntro />
<h2>T - V</h2>
<br/>
TELL MY MISTRESSE <br/>
THAT A LOVER <br/>
TRUE AS LOVE ITSELFE <br/>
DOTH LOVE HER &nbsp;<br/>
<br/>
THAT FRIEND IS TRUE WHO TREASURES YOU &nbsp;<br/>
<br/>
THE EYE DID FIND YE <br/>
HEART DID CHUSE <br/>
THE HAND DOTH BIND <br/>
TILL DEATH DOTH LOOSE <br/>
<br/>
<br/>
THE EYE FINDETH <br/>
THE HEART CHOOSETH <br/>
THE HAND BINDETH <br/>
AND DEATH LOOSETH &nbsp;<br/>
<br/>
THE GIFT IS SMALL BUT LOVE IS ALL &nbsp;<br/>
<br/>
THE RING SHALL TELL I LOVE THEE WELL <br/>
<br/>
THE SIGHT OF THEE IS IOY TO ME &nbsp;<br/>
<br/>
THERE IS NO JEWEL I CAN SEE <br/>
LIKE LOVE THAT'S SENT IN CONSTANCY &nbsp;<br/>
<br/>
THERE IS NO OTHER <br/>
AND I AM HE <br/>
THAT LOVES NO OTHER <br/>
AND THOU ART SHE &nbsp;<br/>
<br/>
THIS AND I UNTILL I DIE <br/>
<br/>
THIS FOR A CERTAIN TRUTH <br/>
TRUE LOVE APPROVES <br/>
THE HEART'S NOT WHERE IT <br/>
LIVES BUT WHERE IT LOVES <br/>
<br/>
THIS GIRDLE HAPLY <br/>
SHALL BE PLAC'D <br/>
TO COMPASS ROUND <br/>
YOUR NEAT SMALL WAIST <br/>
I WERE HAPPY IF IN THIS PLACE <br/>
I MIGHT THY SLENDER WAIST IMBRACE &nbsp;<br/>
<br/>
THIS HATH NO END MY SWEETEST FRIEND &nbsp;<br/>
<br/>
THIS HATH NO END <br/>
MY SWEETEST FRIEND <br/>
OUR LOVES BE SO <br/>
NO ENDING KNOW <br/>
<br/>
THIS RING DOTH BINDE BODY AND MINDE &nbsp;<br/>
<br/>
THIS TAKE FOR MY SAKE &nbsp;<br/>
<br/>
THE WORLD HATH <br/>
STRIVED TO PART <br/>
YET GOD HATH JOYNED US <br/>
HAND AND HEART &nbsp;<br/>
<br/>
THOE MANY THOUGHT <br/>
US TWO TO SEVER <br/>
YET GOD HATH JOYNED <br/>
US TWO TOGETHER <br/>
<br/>
<br/>
THOU WERT NOT HANDSOM WISE BUT RICH <br/>
TWAS THAT WHICH DID MY EYES BEWITCH &nbsp;<br/>
<br/>
THOUGH THESE GLOVES <br/>
BE WHITE AND FAIR <br/>
YET THY HANDS <br/>
MORE WHITER ARE &nbsp;<br/>
<br/>
THY CONSENT IS MY CONTENT &nbsp;<br/>
<br/>
THY FRIEND AM I AND SO WILL DYE &nbsp;<br/>
<br/>
THY HAPPY CHOYCE MAKES ME REJOYCE <br/>
<br/>
TILL DEATH DEPART YOU HAVE MY HEART &nbsp;<br/>
<br/>
TILL DEATH DEVIDE WHAT ERE BETIDE &nbsp;<br/>
<br/>
TILL THAT FROM THEE <br/>
I HOPE TO GAIN <br/>
ALL SWEET IS SOWRE <br/>
ALL PLEASURE PAIN &nbsp;<br/>
<br/>
TIS IN YOUR WILL TO SAVE OR KILL &nbsp;<br/>
<br/>
TIS THY DESERT HATH WOONE MY HEART <br/>
<br/>
<br/>
TIS TRUE AS OLD HOT LOVE SOON COLD <br/>
<br/>
TIS VAIN FOR TO RESIST <br/>
WOMEN DO WHAT THEY LIST &nbsp;<br/>
<br/>
TO BODYES AND ONE HARTE &nbsp;<br/>
<br/>
To GOD'S DECREE WEE BOTH AGREE &nbsp;<br/>
<br/>
TO LIVE IN LOVE I LOVE TO LIVE <br/>
<br/>
TO LOVE AND PEACE GOD GIVES INCREASE &nbsp;<br/>
<br/>
To LOVE AS I DO THEE <br/>
IS TO LOVE NONE BUT ME &nbsp;<br/>
<br/>
TO ME BY FAR MORE FAIR <br/>
IS MY FAIR ANNE <br/>
THEN SWEET CHEEKT LEDA <br/>
WITH HER SILVER SWAN <br/>
<br/>
THAT I NERE SAW <br/>
BUT HAVE THE PICTURE SEEN <br/>
AND WISHT MY SELF BETWEEN <br/>
THINE ARMS SWEET NAN &nbsp;<br/>
<br/>
TO ME TILL DEATH AS DEAR AS BREATH <br/>
<br/>
TO THEE I WISH ETERNALL BLISS &nbsp;<br/>
<br/>
TO THEE MY HEART I GIVE <br/>
WHILEST I HERE DO LIVE &nbsp;<br/>
<br/>
TO THEE MY LOVE I'LL CONSTANT PROVE &nbsp;<br/>
<br/>
TRUE LOVERS HARTES DEATH ONLY PARTES &nbsp;<br/>
<br/>
TWAS GOD TO THEE DIRECTED ME<br/>
<br/>
TWEEN HOPE AND SAD <br/>
DESPAIR I SAIL <br/>
THY HELP I CRAVE <br/>
MY GRIEF THE SEA <br/>
THY BREATH THE SAIL <br/>
MAY SINK- OR SAVE &nbsp;<br/>
<br/>
TWO HEARTS ARE KNYTTE AS <br/>
HEAVEN THOUGHT FYTTE <br/>
<br/>
UNITED HARTES DEATH ONLY PARTES &nbsp;<br/>
<br/>
VENUS NAKED IN HER CHAMBER <br/>
WOUNDS MORE DEEP THAN MARS IN ARMOUR <br/>
<br/>
VERTUE AND LOVE ARE FROM ABOVE &nbsp;<br/>
<br/>
<br/>
WEE JOYNE OUR LOV IN GOD ABOVE &nbsp;<br/>
<br/>
WE STRANGELY MET AND SO DO MANY <br/>
BUT NOW AS TRUE AS EVER ANY &nbsp;<br/>
<br/>
WHAT POSIES FOR OUR WEDDING RINGS <br/>
WHAT GLOVES WE'LL GIVE AND RIBAININGS <br/>
<br/>
WHEN LOVE IS DEAD MY JOYS ARE FLED &nbsp;<br/>
WHEN MONEY'S LOW THIS RING MUST GO &nbsp;<br/>
<br/>
WHEN ROCKS REMOVE I'LL CEASE TO LOVE &nbsp;<br/>
<br/>
WHEN THIS ABOUT THINE ARM DOTH REST <br/>
REMEMBER HER THAT LOVES THEE BEST <br/>
<br/>
WHEN THIS YOU SEE REMEMBER ME <br/>
<br/>
<br/>
<br/>
WHEN YOU SEE THIS GIVE IT A KISS &nbsp;<br/>
<br/>
WHEARE HEARTES AGREE NO STRIP CAN BE &nbsp;<br/>
<br/>
WHERE HEARTS ARE TRUE FEW WORDS WILL DO &nbsp;<br/>
<br/>
WHERE ONCE I CHOOSE I NE'ER REFUSE &nbsp;<br/>
<br/>
WILST LIFE IS MYN MY HEART IS THYN <br/>
<br/>
WIT WEALTH AND BEAUTY ALL DO WELL <br/>
BUT CONSTANT LOVE DOTH FAR EXCELL &nbsp;<br/>
<br/>
WITH HEART AND HAND AT YOUR COMMAND &nbsp;<br/>
WITHIN THY BREAST MY HARTE DOTH REST &nbsp;<br/>
<br/>
YOU AND I WILL LOVE AND DIE &nbsp;<br/>
<br/>
YOU AND I WILL LOVERES DYE <br/>
<br/>
<br/>
YOU ARE MY FRIEND UNTO THE END &nbsp;<br/>
<br/>
<br/>
YOU ARE THAT ONE <br/>
FOR WHOM ALONE <br/>
MY HEART DOTH ONLY CARE <br/>
THEN DO BUT JOYN <br/>
YOUR HEART WITH MINE <br/>
AND WE WILL MAKE A PAIR &nbsp;<br/>
<br/>
YOU NEVER KNEU <br/>
A HEART MORE TRUE &nbsp;<br/>
<br/>
YOUR FRIEND AM I ASHUREDLY <br/>
<br/>
YOUR SIGHT IS MY DELIGHT <br/>
<br/>
YOURS LOVING FRIEND TILL DETH US END &nbsp;<br/>
<br/>
<hr/>
   </Layout>
  )
}

export default Posies_t
