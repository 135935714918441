import React, { Fragment } from "react";
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'


const RingPoemsIntro = () => {

    return (
    <Fragment>
       <Helmet>
 
        <meta name="description" content="Traditional English short rhyming love poems (posy ring poems)" />

      </Helmet>

     <div>
     <div align="center">
     <h1>Very short love poems engraved on vintage wedding rings - tokens of devotion and promise</h1>
    <p> These very short love poems and rhymed inscriptions can be found engraved on antique posy rings
(also 'posie' or 'posey rings'). They
repesent deep intimate feelings of people who have long since perished. Such
rings were given on various occasions, but as one can clearly see,
mostly as tokens of engagement or as wedding rings. Not many of them can be considered true gems of English literature, but the power of these short
love poems (if any) is in their romantic sincerity and old time charm. Their brevity
is naturally dictated by the limitations of space - only one or two
phrases used to fit on a ring (today's engraving machines can do much better than that!). Modern day Valentines are obviously
influenced by this genre, but some of these rhymed lines have
apparently gone unnoticed for centuries. This collection is fairly unique, although not complete. </p>


<p><b><Link activeClassName ='selected' to="../ringpoems_a">A - F</Link> | <Link  activeClassName ='selected' to="../ringpoems_g">G - L</Link> | <Link  activeClassName ='selected' to="../ringpoems_m">M - S</Link> | <Link  activeClassName ='selected' to="../ringpoems_t">T - V</Link></b></p>
</div>

     </div>
     </Fragment>
    )
  }
  
  export default RingPoemsIntro
  